import axios from 'axios';

export default {
  post: {
    getClickUUID: async (accountSlug, utmData) => {
      const { data } = await axios.post('/public/tracking/clicks', {
        accountSlug,
        utm_source: utmData?.utm_source,
        utm_medium: utmData?.utm_medium,
        referrer: document.referrer || utmData?.referrer,
        // We'll start with only these three for now.
      });
      return data.sg_click_uuid;
    },
  },
};
