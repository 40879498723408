<template>
  <div
    class="app-container"
    :class="{
      desktop: isDesktop,
      'is-ios': isIOS,
      'booking-flow': isBookingFlow,
    }"
  >
    <router-view />
  </div>
</template>

<script>
import { clickTracking } from '@/utils/clickTracking';

export default {
  mounted() {
    this.importGrecaptchaScript();
    this.handleQueryParams();
  },
  methods: {
    importGrecaptchaScript() {
      if (document.querySelector('.grecaptcha-script')) return;

      const script = document.createElement('script');
      script.className = 'grecaptcha-script';
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    },
    handleQueryParams() {
      // Handle RWG token
      if (this.$route.query.rwg_token) {
        const queryRwgToken = this.$route.query.rwg_token;
        const rwgToken = JSON.stringify({
          token: queryRwgToken,
          timestamp: Date.now(),
        });
        localStorage.setItem('rwg_token', rwgToken);
      }

      // Handle click tracking UUID
      if (this.$route.query.sg_click_uuid) {
        clickTracking.setClickUUID(this.$route.query.sg_click_uuid);
      } else {
        clickTracking.fetchClickUUID(this.$route.query.accountSlug, this.$route.query);
      }
    },
  },
  computed: {
    isDesktop() {
      const { desktop } = this.$route.query;
      if (desktop === undefined) return false;
      return !!Number(desktop);
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isBookingFlow() {
      return this.$route.name === 'general-repair';
    },
  },
};
</script>
