const CLICK_UUID_KEY = 'sg_click_uuid';
const EXPIRATION_DAYS = 3;
import ClickTracking from '../services/ClickTracking';

export const clickTracking = {
  setClickUUID(uuid) {
    if (!uuid) return;

    const trackingData = {
      uuid,
      expires: Date.now() + EXPIRATION_DAYS * 24 * 60 * 60 * 1000,
    };

    localStorage.setItem(CLICK_UUID_KEY, JSON.stringify(trackingData));
  },

  getClickUUID() {
    try {
      const trackingData = JSON.parse(localStorage.getItem(CLICK_UUID_KEY));

      if (!trackingData) return null;

      // Check if expired
      if (Date.now() > trackingData.expires) {
        localStorage.removeItem(CLICK_UUID_KEY);
        return null;
      }

      return trackingData.uuid;
    } catch {
      return null;
    }
  },
  async fetchClickUUID(accountSlug, utmData) {
    try {
      const clickUUID = await ClickTracking.post.getClickUUID(accountSlug, utmData);
      this.setClickUUID(clickUUID);
    } catch (error) {
      console.error('Error fetching click UUID', error);
    }
  },
  clearClickUUID() {
    localStorage.removeItem(CLICK_UUID_KEY);
  },
};
